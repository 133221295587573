import React, { useState } from 'react';
import { Page } from '../../components/layout';
import {
  DefaultPageProps,
  withApolloClient,
  withAuthServerSideProps,
} from '../../services/authMiddleware';
import SocialLoginButtons from '../../components/Welcome/SocialLoginButtons';
import { initializeApollo } from '../../services/apolloClient';
import { getUserQuery } from '../../graphql/queries';
import { useQuery } from '@apollo/client';
import {
  ContinueWithEmailForm,
  ContinueWithEmailMode,
} from '../../components/Login/ContinueWithEmailForm';
import { LinkWrapper } from '../../components/LinkWrapper/LinkWrapper';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import TRANSLATION_FILES from '../../public/locales/constants';

type LoginPageProps = { userNotAuthed?: boolean } & DefaultPageProps;

function Login({
  userNotAuthed,
  requestCookieConsent,
  cookieConsentGiven,
}: LoginPageProps) {
  const { t } = useTranslation(TRANSLATION_FILES.signInUp);

  const [isLoading, setIsLoading] = useState(false);

  const { data = { getUser: null } } = useQuery(getUserQuery, {
    skip: userNotAuthed,
  });
  const user = data.getUser;

  return (
    <Page title="Vytal-Login" user={user}>
      <h2 className="headline mb-3">{t('logIn')}</h2>
      <div className="flex flex-row mb-6 vytal-dark">
        <p>{t('newUser')}</p>
        &nbsp;
        <LinkWrapper href="/welcome/register">
          <a className="font-semibold">{t('createAccount')}</a>
        </LinkWrapper>
      </div>

      <div className="flex flex-col">
        <ContinueWithEmailForm
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          loginMode={ContinueWithEmailMode.LOGIN}
        />
        <div className="mt-5" />
        <SocialLoginButtons
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          requestCookieConsent={requestCookieConsent}
          cookieConsentGiven={cookieConsentGiven}
        />
      </div>
    </Page>
  );
}

export default withApolloClient(Login);

export const getServerSideProps = withAuthServerSideProps(
  async (authContext, locale) => {
    const translations = await serverSideTranslations(locale || 'en', [
      TRANSLATION_FILES.signInUp,
      TRANSLATION_FILES.common,
      TRANSLATION_FILES.leftScreen,
      TRANSLATION_FILES.vytalBowls,
    ]);
    console.log(authContext);
    if (!authContext || !authContext.jwt)
      return {
        props: {
          userNotAuthed: true,
          ...translations,
        },
      };

    const apolloClient = initializeApollo(authContext);

    await apolloClient.query({
      query: getUserQuery,
    });

    return {
      props: {
        // initialApolloState: apolloClient.cache.extract(),
        ...translations,
      },
    };
  },
  { requireAuth: false },
);
